export default {
    PART_TYPES: [
        [4, 'Custom'],
        [5, 'ERV'],
        [2, 'Iso'],
        [3, 'Misc'],
        [1, 'Unit'],
        [6, 'VRF']
    ],
    /**
     * This list is based off of miscellaneous-typed curbs with `screw` in the nomenclature
     */
    SCREW_TYPES: [
        [53752, 'SCREWS, WIND/SEISMIC ATTACHMENT'],
        [57880, 'SCREWS, 5" LONG, WIND/SEISMIC ATTACHMENT'],
        [57881, 'SCREWS, 6" LONG, WIND/SEISMIC ATTACHMENT'],
        [61649, 'SCREWS, 4" LONG, WIND/SEISMIC ATTACHMENT'],
        [61650, '#12 x 1/4", LONG TEK SCREW'],
        [61651, '#12 X 1-1/4" LONG TEK SCREW'],
        [63714, '#14TEKScrew-5005kit'],
        [63715, '#14TEKScrew-5035kit'],
        [63716, '#14TEKScrew-5100kit'],
        [63717, '#14TEKScrew-5105kit'],
        [63718, '#14TEKScrew-5020kit'],
        [65741, 'SCREWS, #12 X 1" LONG'],
        [65742, 'SCREWS, #12 X 2" LONG'],
        [76880, 'SCREWS, #12 x 3/4" LONG'],
        [77190, 'SET- SEISMIC/WIND CALC SCREWS'],
        [77337, 'SCREWS, 3" LONG, WIND/SEISMIC ATTACHMENT'],
        [77363, 'SCREWS, 3" LONG, WIND/SEISMIC ATTACHMENT'],
        [77494, 'SCREWS, 3/4" LONG, WIND/SEISMIC ATTACHMENT #14'],
        [92248, '#12 x 4" LONG TEK SCREW'],
        [99806, '#14TEKScrew-5110kit']
    ]
};