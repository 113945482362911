<template>
    <div>
        <h1>Brand Sales</h1>
        <form @submit.prevent="runReport">
            <Options />
            <chart v-bind:results="state.results[state.report]"></chart>
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./brand_sales_opt";
    import Results from "./utils/Results";
    import Chart from "@/components/utils/Chart"

    export default {
        name: "brand_sales",
        components: {
            Results,
            Options,
            Chart,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    dateFrom: {val: new Date(new Date().setDate(1)).toLocaleDateString(), qsField: '[dates][actual_ship_from]'},
                    dateTo: {val: new Date().toLocaleDateString(), qsField: '[datetimes][actual_ship_to]'},
                    cdiLocationID: {val: '0', qsField: '[numbers][cdi_location_id]'},
                    salesPerson: {val: '', qsField: '[subquery][tracker_sales_user]'},
                    partType: {val: '1', qsField: '[numbers][cdi_part_type_id]'},
                    reportType: {val: 'sales', qsField: '[rtype]'},
                    hide9: {val: '1', qsField: '[hide_9]'},
                    chart: {val: '1', qsField: '[chart]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        }, created() {
            this.$appStore.setTitle(['Brand Sales']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields,this.dataFields);
        },
    }
</script>

<style scoped>

</style>