<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Ship Date From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.dateFrom.val" />
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Ship Date To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.dateTo.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sales Person</label><Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.salesPerson.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Part Type</label>
                <select class="form-control" v-model="state.optionFields.partType.val">
                    <option value="0">All</option>
                    <option v-for="[partTypeID, part] in cache.partTypeCache.PART_TYPES" v-bind:key="partTypeID" :value="partTypeID">{{ part }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Report Type</label><select name="params[rtype]" v-model="state.optionFields.reportType.val" class="form-control">
                <option value='sales'>Total Sales</option>
                <option value='count'>Units Sold</option>
            </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.hide9.val" > Hide 9999s and ERs</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.chart.val" > Chart</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import partTypeCache from '@/cache/partType.cache.js';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "brand_sales_opt",
        components: { Typeahead, DatePicker },
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    partTypeCache
                }
            }
        }
    }
</script>

<style scoped>

</style>